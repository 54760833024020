export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REFRESH_TOKEN = "REFRESH_TOKEN";

export const SYNC_COURSES = "SYNC_COURSES";
export const LOAD_COURSE = "LOAD_COURSE";
export const LOAD_LESSON = "LOAD_LESSON";
export const SET_INDEX = "SET_INDEX";

export const EDITOR_RENDER = "EDITOR_RENDER";
export const EDITOR_REFRESH = "EDITOR_REFRESH";
export const EDITOR_RECOVER = "EDITOR_RECOVER";
export const EDITOR_CHANGE = "EDITOR_CHANGE";
export const EDITOR_UPDATE_SAVEDTEXT = "EDITOR_UPDATE_SAVEDTEXT";
export const SAVE_START = "SAVE_START";

export const SYNC_USER_PROJ = "SYNC_USER_PROJ";
export const SYNC_EXAMP_PROJ = "SYNC_EXAMP_PROJ";
export const DELETE_PROJ = "DELETE_PROJ";

export const NAME_SCENE = "NAME_SCENE";
export const NEW_SCENE = "NEW_SCENE";
export const LOAD_SCENE = "LOAD_SCENE";
export const TOGGLE_COORD_SKY = "TOGGLE_COORD_SKY";
export const CHANGE_SKY_COLOR = "CHANGE_SKY_COLOR";
export const RESET_SKY_COLOR = "RESET_SKY_COLOR";
export const CHANGE_FLOOR_COLOR = "CHANGE_FLOOR_COLOR";
export const RESET_FLOOR_COLOR = "RESET_FLOOR_COLOR";
export const CHANGE_CAM_MODE = "CHANGE_CAM_MODE";
export const SET_CAMERA = "SET_CAMERA";
export const CHANGE_PERSPECTIVE = "CHANGE_PERSPECTIVE";
export const CHANGE_VIEW = "CHANGE_VIEW";
export const TOGGLE_FLOOR = "TOGGLE_FLOOR";
export const LOAD_SETTINGS = "LOAD_SETTINGS";
export const CHANGE_SETTINGS = "CHANGE_SETTINGS";
export const RESET_SETTINGS = "RESET_SETTINGS";
export const ADD_COLLECTION = "ADD_COLLECTION";
export const REMOVE_COLLECTION = "REMOVE_COLLECTION";
export const SET_DESC = "SET_DESC";
export const SET_NAME_DESC = "SET_NAME_DESC";
export const TOGGLE_DEFAULT_LIGHT = "TOGGLE_DEFAULT_LIGHT";
export const TOGGLE_CAST_SHADOW = "TOGGLE_CAST_SHADOW";
export const TOGGLE_LIGHT_INDICATOR = "TOGGLE_LIGHT_INDICATOR";
export const TOGGLE_CURSOR = "TOGGLE_CURSOR";
export const UPDATE_MOVE_SPEED = "UPDATE_MOVE_SPEED";

export const SYNC_COLLECTIONS = "SYNC_COLLECTIONS";
export const SYNC_COLLECTION = "SYNC_COLLECTION";
export const DELETE_COLLECTION = "DELETE_COLLECTION";

export const SYNC_USER_SETTINGS = "SYNC_USER_SETTINGS";
export const UPDATE_FONT_SIZE = "UPDATE_FONT_SIZE";

export const LOAD_REF_EX = "LOAD_REF_EX";
